<template>
  <v-dialog
    v-model="dialogPreferencia"
    @click:outside="$emit('update:dialogPreferencia', false)"
    @keydown.esc="$emit('update:dialogPreferencia', false)"
    scrollable
    fullscreen
  >
    <v-card tile>
      <v-card-title class="font-weight-bold accent"
        >Preferências
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          icon
          @click="$emit('update:dialogPreferencia', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-3 py-3 fill-height">
        <v-row class="fill-height">
          <v-col
            cols="12"
            md="2"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
            class="pt-8"
          >
            <v-card flat class="mt-1">
              <v-tabs v-model="tab" vertical>
                <v-tab class="justify-start">Temas</v-tab>
              </v-tabs>
            </v-card>
          </v-col>
          <v-col cols="12" md="10" class="pa-0">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ThemeChanger />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Preferencias",

  props: {
    dialogPreferencia: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ThemeChanger: () => import("./tabs/ThemeChanger.vue"),
  },

  data() {
    return {
      tab: 0,
    };
  },

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
